<template>
  <div class="mx-4" style="position: relative">
    <CityPassesTitleComponent @radioChanged="radioChanged" />
    <CityPassesCard
      @get_city_passes="getCityPasses"
      ref="cards"
      :cityPassList="cityPassList"
      :radio="radio"
    />
    <v-btn
      v-if="admin"
      fab
      color="#E62076"
      right
      dark
      small
      top
      absolute
      :style="
        $vuetify.breakpoint.xs || $vuetify.breakpoint.sm
          ? 'margin-top: 23px'
          : 'margin-top: 35px'
      "
      @click="openModal"
    >
      <v-icon>mdi-plus</v-icon>
    </v-btn>
    <add-city-pass-modal
      ref="modal"
      v-if="cityPassModal"
      @close_modal="closeModal"
      @added_city_pass="addedCityPass"
    />
  </div>
</template>

<script>
import CityPassesTitleComponent from "./CityPassesTitleComponent.vue";
import CityPassesCard from "./CityPassesCard.vue";
import AddCityPassModal from "./AddCityPassModal.vue";
import CitypassService from "../../requests/Citypass/CitypassService";
import { mapGetters } from "vuex";
import mobile from "@/mixins/mobileMixin";

export default {
  mixins: [mobile],
  components: {
    CityPassesTitleComponent,
    CityPassesCard,
    AddCityPassModal,
  },
  data: () => ({
    admin: false,
    cityPassModal: false,
    radio: "All",
    cityPassList: [],
  }),
  mounted() {
    this.$vuetify.goTo(0, 0);
    this.setMobile(false);
    this.setMobileMenu(false);
    if (this.loggedUser && this.loggedUser.admin) {
      this.admin = true;
    }
    this.getCityPasses();
  },
  watch: {
    loggedUser: {
      handler() {
        if (this.loggedUser && this.loggedUser.admin) {
          this.admin = true;
          this.getCityPasses();
        }
      },
    },
  },
  computed: {
    ...mapGetters(["loggedUser"]),
  },
  methods: {
    radioChanged(radio) {
      this.radio = radio;
      console.log(radio);
    },
    addedCityPass() {
      this.$refs.cards.startLoader();
      this.getCityPasses();
    },
    async getCityPasses() {
      await CitypassService.getCitypasses().then((res) => {
        this.cityPassList = res.result;
        this.$refs.cards.setCityPasses(res.result);
      });
    },
    closeModal() {
      this.cityPassModal = false;
    },
    openModal() {
      this.cityPassModal = true;
    },
  },
};
</script>