<template>
  <div>
    <v-snackbar top v-model="snackbar" color="error">
      <v-row align="center">
        <VIcon class="mx-3">mdi-alert</VIcon
        >{{
          "attractions_placeholder_please_choose_attraction" | localize
        }}</v-row
      ></v-snackbar
    >
    <v-dialog
      v-model="cityPassModal"
      scrollable
      persistent
      max-width="600px"
      style="overflow-y: auto"
    >
      <v-card style="position: relative">
        <VIcon
          style="position: absolute; right: 12px; top: 12px"
          large
          @click="cityPassModal = false"
          >mdi-close</VIcon
        >
        <v-card-title v-if="!editCityPass">{{
          "city_passes_label_add_new_city_pass" | localize
        }}</v-card-title>
        <v-card-title v-if="editCityPass">{{
          "city_passes_label_edit_city_pass" | localize
        }}</v-card-title>
        <v-card-text ref="content">
          <v-radio-group row mandatory v-model="cityPass.type">
            <v-radio
              :label="'city_passes_tourists' | localize"
              value="0"
              color="#E62076"
            ></v-radio>
            <v-radio
              :label="'city_passes_locals' | localize"
              value="1"
              color="#E62076"
            ></v-radio>
          </v-radio-group>
          <VMenu offset-y :close-on-content-click="true">
            <template v-slot:activator="{ on, attrs }">
              <div class="color-wrapper" v-bind="attrs" v-on="on">
                {{ "city_passes_label_color_city_pass" | localize }}
                <span
                  class="ml-4 color-block"
                  :style="`background:` + cityPass.color"
                ></span>
                <VIcon> mdi-menu-down </VIcon>
              </div>
            </template>
            <v-color-picker
              width="550px"
              show-swatches
              hide-canvas
              hide-sliders
              hide-inputs
              :swatches="swatches"
              v-model="cityPass.color"
            ></v-color-picker>
          </VMenu>

          <v-text-field
            color="#E62076"
            outlined
            dense
            v-model="cityPass.titleEN"
            :error-messages="titleENError | localize"
            maxlength="160"
            class="mt-4"
            @blur="$v.cityPass.titleEN.$touch()"
            ><template v-slot:label>
              <div>
                {{ "city_passes_placeholder_title_city_pass_en" | localize
                }}<span class="req"> *</span>
              </div>
            </template>
          </v-text-field>
          <v-text-field
            color="#E62076"
            outlined
            dense
            v-model="cityPass.titleES"
            maxlength="160"
            :error-messages="titleESError | localize"
            :label="'city_passes_placeholder_title_city_pass_es' | localize"
            @blur="$v.cityPass.titleES.$touch()"
          >
            <template v-slot:label>
              <div>
                {{ "city_passes_placeholder_title_city_pass_es" | localize
                }}<span class="req"> *</span>
              </div>
            </template>
          </v-text-field>
          <!-- Joel change ordering field for Mazatlan -->
          <v-text-field
            v-if="mazatlan"
            color="#E62076"
            outlined
            dense
            v-model="cityPass.term"
            :error-messages="termError | localize"
            @wheel="$event.target.blur()"
            @keypress="isNumber($event, cityPass.term, 'days')"
            type="number"
            min="1"
            step="any"
            :label="'city_passes_placeholder_how_many_days' | localize"
            @blur="$v.cityPass.term.$touch()"
          >
            <template v-slot:label>
              <div>
                {{ "city_passes_placeholder_how_many_days" | localize
                }}<span class="req"> *</span>
              </div>
            </template></v-text-field
          >
          <v-expansion-panels
            style="
              border: 1px rgba(0, 0, 0, 0.38) solid !important;
              box-shadow: none !important;
            "
            class="mb-6"
            v-if="mazatlan"
          >
            <v-expansion-panel>
              <v-expansion-panel-header
                style="height: 40px !important"
                class="my-0 px-3"
              >
                {{ "city_passes_placeholder_select_days" | localize
                }}<span class="req">&nbsp;*</span>
              </v-expansion-panel-header>
              <v-expansion-panel-content>
                <v-radio-group row v-model="cityPass.days">
                  <v-radio
                    label="Monday - Thursday"
                    value="1,2,3,4"
                    color="#E62076"
                  >
                    <template v-slot:label>
                      {{ "city_passes_Monday" | localize }} -
                      {{ "city_passes_Thursday" | localize }}
                    </template>
                  </v-radio>
                  <v-radio
                    label="Monday - Friday"
                    value="1,2,3,4,5"
                    color="#E62076"
                  >
                    <template v-slot:label>
                      {{ "city_passes_Monday" | localize }} -
                      {{ "city_passes_Friday" | localize }}
                    </template>
                  </v-radio>
                  <v-radio
                    label="Monday - Sunday"
                    value="1,2,3,4,5,6,7"
                    color="#E62076"
                  >
                    <template v-slot:label>
                      {{ "city_passes_Monday" | localize }} -
                      {{ "city_passes_Sunday" | localize }}
                    </template></v-radio
                  >
                  <v-radio
                    label="Friday - Sunday"
                    value="5,6,7"
                    color="#E62076"
                  >
                    <template v-slot:label>
                      {{ "city_passes_Friday" | localize }} -
                      {{ "city_passes_Sunday" | localize }}
                    </template></v-radio
                  >
                  <v-radio
                    label="Saturday - Sunday"
                    value="6,7"
                    color="#E62076"
                  >
                    <template v-slot:label>
                      {{ "city_passes_Saturday" | localize }} -
                      {{ "city_passes_Sunday" | localize }}
                    </template></v-radio
                  >
                </v-radio-group>
              </v-expansion-panel-content>
            </v-expansion-panel>
          </v-expansion-panels>
          <v-text-field
            v-if="mazatlan"
            color="#E62076"
            outlined
            dense
            v-model="cityPass.priceAdults"
            :error-messages="priceAdultsError | localize"
            @keypress="isNumber($event, cityPass.priceAdults, 'adults')"
            @wheel="$event.target.blur()"
            type="number"
            step="any"
            min="1"
            :label="'city_passes_placeholder_price_adults' | localize"
            @blur="$v.cityPass.priceAdults.$touch()"
          >
            <template v-slot:label>
              <div>
                {{ "city_passes_placeholder_price_adults" | localize
                }}<span class="req"> *</span>
              </div>
            </template>
          </v-text-field>
          <v-text-field
            v-if="mazatlan"
            color="#E62076"
            outlined
            dense
            v-model="cityPass.priceKids"
            :error-messages="priceKidsError | localize"
            @wheel="$event.target.blur()"
            @keypress="isNumber($event, cityPass.priceKids, 'kids')"
            type="number"
            step="any"
            min="0"
            :label="'city_passes_placeholder_price_kids' | localize"
            @blur="$v.cityPass.priceKids.$touch()"
          >
            <template v-slot:label>
              <div>
                {{ "city_passes_placeholder_price_kids" | localize
                }}<span class="req"> *</span>
              </div>
            </template>
          </v-text-field>
          <v-text-field
            color="#E62076"
            outlined
            dense
            :error-messages="freeAttractionsError | localize"
            v-model="cityPass.free_attractions"
            @wheel="$event.target.blur()"
            @keypress="
              isNumber($event, cityPass.free_attractions, 'attractions')
            "
            type="number"
            min="1"
            step="any"
            :label="'cart_label_free_attractions' | localize"
            @blur="$v.cityPass.free_attractions.$touch()"
          >
            <template v-slot:label>
              <div>
                {{ "cart_label_free_attractions" | localize
                }}<span class="req"> *</span>
              </div>
            </template>
          </v-text-field>
          <div v-if="mazatlan">
            <v-row class="mb-3">
              <v-col
                v-for="(category, index) in cityPass.attraction_categories"
                :key="index"
                class="col-12 col-sm-6"
              >
                <div style="display: flex; align-items: center; height: 50px">
                  <v-row align="center">
                    <v-col class="col-7 py-0">
                      <v-checkbox
                        v-model="category.checked"
                        hide-details="auto"
                        :label="
                          $options.filters.localize('mzt_category_label') +
                          ' ' +
                          category.uuid
                        "
                        @change="
                          category.count = '';
                          category.errors = [];
                          categoriesErrors = [];
                        "
                        class="mt-0"
                        color="#E62076"
                      >
                      </v-checkbox>
                    </v-col>
                    <v-col class="py-0">
                      <v-text-field
                        color="#E62076"
                        outlined
                        hide-details="auto"
                        dense
                        v-if="category.checked"
                        @wheel="$event.target.blur()"
                        @keypress="
                          isNumber($event, category.count, 'attractions')
                        "
                        v-model="category.count"
                        @input="
                          category.errors = [];
                          categoriesErrors = [];
                        "
                        type="number"
                        min="1"
                        class="pt-1"
                        step="any"
                        :label="'Count'"
                      >
                      </v-text-field>
                    </v-col>
                    <div
                      style="
                        font-size: 12px;
                        color: #ff5252;
                        line-height: 12px;
                        min-height: 24px;
                      "
                      class="ml-4"
                    >
                      {{ category.errors[0] | localize }}
                    </div>
                  </v-row>
                </div>
              </v-col>
              <span style="font-size: 12px; color: #ff5252" class="ml-4">{{
                categoriesErrors[0]
              }}</span>
            </v-row>
          </div>
          <!-- Joel change ordering field for Mazatlan -->
          <v-autocomplete
            v-if="mazatlan"
            dense
            outlined
            color="#E62076"
            item-color="#E62076"
            multiple
            return-object
            attach
            :item-text="'title'"
            v-model="cityPass.attractions"
            :items="Object.values(attractions)"
            :menu-props="{ bottom: true, offsetY: true, closeOnClick: true }"
            :label="'city_passes_placeholder_choose_attractions' | localize"
            :error-messages="attractionError | localize"
            @blur="$v.cityPass.attractions.$touch()"
            ><template #selection="{ item }">
              <v-chip small dark color="#E62076">{{ item.title }}</v-chip>
            </template>
            <template v-slot:label>
              <div>
                {{ "city_passes_placeholder_choose_attractions" | localize
                }}<span class="req"> *</span>
              </div>
            </template></v-autocomplete
          >
          <!-- <v-text-field
            dense
            outlined
            color="#E62076"
            :label="
              'city_passes_placeholder_discount_other_attractions' | localize
            "
            v-model="cityPass.discount"
            :error-messages="discountError | localize"
            @wheel="$event.target.blur()"
            @keypress="isNumber($event, cityPass.discount, 'discount')"
            @input="discountCitypassError = []"
            type="number"
            min="1"
            step="any"
            @blur="$v.cityPass.discount.$touch()"
          >
            <template v-slot:label>
              <div>
                {{
                  "city_passes_placeholder_discount_other_attractions"
                    | localize
                }}<span class="req"> *</span>
              </div>
            </template></v-text-field
          >
          <v-text-field
            dense
            outlined
            color="#E62076"
            v-model="cityPass.discount_max"
            :error-messages="discountMaxError | localize"
            @wheel="$event.target.blur()"
            @keypress="isNumber($event, cityPass.discount_max, 'discount')"
            @input="discountCitypassError = []"
            type="number"
            min="1"
            step="any"
            :label="'city_passes_maximum_discount_placeholder' | localize"
            @blur="$v.cityPass.discount_max.$touch()"
          >
            <template v-slot:label>
              <div>
                {{ "city_passes_maximum_discount_placeholder" | localize
                }}<span class="req"> *</span>
              </div>
            </template></v-text-field
          > -->
          <v-autocomplete
            v-if="!mazatlan"
            dense
            outlined
            color="#E62076"
            item-color="#E62076"
            multiple
            return-object
            attach
            :item-text="'title'"
            v-model="cityPass.attractions"
            :items="Object.values(attractions)"
            :menu-props="{ bottom: true, offsetY: true, closeOnClick: true }"
            :label="'city_passes_placeholder_choose_attractions' | localize"
            :error-messages="attractionError | localize"
            @blur="attractionsError"
            ref="autocompleteRef"
            @change="clearInputText"
            ><template #selection="{ item }">
              <v-chip small dark color="#E62076" @click="clearInputText">{{
                item.title
              }}</v-chip>
            </template>
            <template v-slot:label>
              <div>
                {{ "city_passes_placeholder_choose_attractions" | localize
                }}<span class="req"> *</span>
              </div>
            </template></v-autocomplete
          >
          <div
            class="mb-5"
            v-if="cityPass.attractions.length"
            ref="attraction_discount"
          >
            <v-row
              align="center"
              no-gutters
              class="mb-2"
              v-for="attraction in cityPass.attractions"
              :key="attraction.uid"
            >
              <v-col
                style="color: #e62076; font-size: 14px; font-weight: 500"
                >{{ attraction.title }}</v-col
              >
              <v-col
                ><v-checkbox
                  v-model="attraction.is_additional"
                  hide-details
                  :label="'city_passes_included_checkbox' | localize"
                  class="mt-0"
                  color="#E62076"
                  :disabled="isAdditionalChoosed && !attraction.is_additional"
                  @click="isAdditionalChoosed = !isAdditionalChoosed"
                >
                  <template v-slot:label>
                    <span
                      :style="
                        isAdditionalChoosed && !attraction.is_additional
                          ? 'color: silver'
                          : 'color: black'
                      "
                      >{{ "city_passes_included_checkbox" | localize }}</span
                    >
                  </template>
                </v-checkbox></v-col
              >
              <v-col
                cols="2"
                :class="attraction.is_additional ? 'd-none' : 'd-block'"
                ><v-checkbox
                  v-model="attraction.is_free"
                  hide-details
                  :label="'city_passes_is_free_checkbox' | localize"
                  class="mt-0"
                  color="#E62076"
                ></v-checkbox
              ></v-col>

              <v-col
                cols="4"
                :class="attraction.is_additional ? 'd-none' : 'd-block pl-5'"
              >
                <v-text-field
                  dense
                  outlined
                  v-model="attraction.discount_city_pass"
                  color="#E62076"
                  :label="'city_passes_discount_label' | localize"
                  @keypress="
                    isNumber($event, attraction.discount_city_pass, 'discount')
                  "
                  :disabled="attraction.disabled"
                  @wheel="$event.target.blur()"
                  type="number"
                  hide-details
                  min="1"
                  step="any"
                  @input="discountCitypassError = []"
                ></v-text-field>
              </v-col>
              <v-col
                :class="!attraction.is_additional ? 'd-none' : 'd-block mr-2'"
              >
                <v-text-field
                  dense
                  outlined
                  v-model="attraction.price_adult"
                  @keypress="isNumber($event, attraction.price_adult, 'adults')"
                  color="#E62076"
                  :label="'city_passes_placeholder_price_adults' | localize"
                  @wheel="$event.target.blur()"
                  type="number"
                  hide-details
                  min="1"
                  step="any"
                >
                  <template v-slot:label>
                    <div>
                      {{ "city_passes_placeholder_price_adults" | localize
                      }}<span class="req"> *</span>
                    </div>
                  </template></v-text-field
                >
              </v-col>
              <v-col :class="!attraction.is_additional ? 'd-none' : 'd-block'">
                <v-text-field
                  dense
                  outlined
                  v-model="attraction.price_kids"
                  color="#E62076"
                  :label="'city_passes_placeholder_price_kids' | localize"
                  @wheel="$event.target.blur()"
                  @keypress="isNumber($event, attraction.price_kids, 'kids')"
                  type="number"
                  hide-details
                  min="1"
                  step="any"
                >
                  <div>
                    {{ "city_passes_placeholder_price_kids" | localize
                    }}<span class="req"> *</span>
                  </div></v-text-field
                >
              </v-col>
            </v-row>
            <span style="font-size: 12px; color: #ff5252"
              >{{ attractionDiscountError | localize
              }}{{ discountCitypassError | localize }}</span
            >
          </div>

          <v-text-field
            v-if="!mazatlan"
            color="#E62076"
            outlined
            dense
            v-model="cityPass.term"
            :error-messages="termError | localize"
            @wheel="$event.target.blur()"
            @keypress="isNumber($event, cityPass.term, 'days')"
            type="number"
            min="1"
            step="any"
            :label="'city_passes_placeholder_how_many_days' | localize"
            @blur="$v.cityPass.term.$touch()"
          >
            <template v-slot:label>
              <div>
                {{ "city_passes_placeholder_how_many_days" | localize
                }}<span class="req"> *</span>
              </div>
            </template></v-text-field
          >
          <v-expansion-panels
            style="
              border: 1px rgba(0, 0, 0, 0.38) solid !important;
              box-shadow: none !important;
            "
            class="mb-6"
            v-if="!mazatlan"
          >
            <v-expansion-panel>
              <v-expansion-panel-header
                style="height: 40px !important"
                class="my-0 px-3"
              >
                {{ "city_passes_placeholder_select_days" | localize }}
                <span class="req">&nbsp;*</span>
              </v-expansion-panel-header>
              <v-expansion-panel-content>
                <v-radio-group row v-model="cityPass.days">
                  <v-radio
                    label="Monday - Thursday"
                    value="1,2,3,4"
                    color="#E62076"
                  >
                    <template v-slot:label>
                      {{ "city_passes_Monday" | localize }} -
                      {{ "city_passes_Thursday" | localize }}
                    </template>
                  </v-radio>
                  <v-radio
                    label="Monday - Friday"
                    value="1,2,3,4,5"
                    color="#E62076"
                  >
                    <template v-slot:label>
                      {{ "city_passes_Monday" | localize }} -
                      {{ "city_passes_Friday" | localize }}
                    </template>
                  </v-radio>
                  <v-radio
                    label="Monday - Sunday"
                    value="1,2,3,4,5,6,7"
                    color="#E62076"
                  >
                    <template v-slot:label>
                      {{ "city_passes_Monday" | localize }} -
                      {{ "city_passes_Sunday" | localize }}
                    </template></v-radio
                  >
                  <v-radio
                    label="Friday - Sunday"
                    value="5,6,7"
                    color="#E62076"
                  >
                    <template v-slot:label>
                      {{ "city_passes_Friday" | localize }} -
                      {{ "city_passes_Sunday" | localize }}
                    </template></v-radio
                  >
                  <v-radio
                    label="Saturday - Sunday"
                    value="6,7"
                    color="#E62076"
                  >
                    <template v-slot:label>
                      {{ "city_passes_Saturday" | localize }} -
                      {{ "city_passes_Sunday" | localize }}
                    </template></v-radio
                  >
                </v-radio-group>
              </v-expansion-panel-content>
            </v-expansion-panel>
          </v-expansion-panels>
          <v-text-field
            color="#E62076"
            outlined
            dense
            type="number"
            v-model="cityPass.not_included_discount"
            placeholder="Type here.."
            :label="'city_pass_not_included_discount' | localize"
            min="1"
            :error-messages="notIncludedDiscountError | localize"
          >
            <template v-slot:label>
              <div>
                {{ "city_pass_not_included_discount" | localize
                }}<span class="req"> *</span>
              </div>
            </template>
          </v-text-field>
          <v-textarea
            color="#E62076"
            outlined
            rows="2"
            dense
            auto-grow
            v-model="cityPass.description_en"
          >
            <template v-slot:label>
              <span
                >{{
                  "city_passes_further_deatails_placeholder" | localize
                }}
                EN</span
              >
            </template>
          </v-textarea>
          <v-textarea
            color="#E62076"
            outlined
            rows="2"
            dense
            auto-grow
            v-model="cityPass.description_es"
          >
            <template v-slot:label>
              <span
                >{{
                  "city_passes_further_deatails_placeholder" | localize
                }}
                ES</span
              >
            </template>
          </v-textarea>
          <v-checkbox
            class="mt-0"
            color="#E62076"
            :label="'city_passes_checkbox_hop_on_bus' | localize"
            v-model="cityPass.busFree"
            v-if="!mazatlan"
          ></v-checkbox>
          <v-checkbox
            class="mt-0"
            color="#E62076"
            :label="'city_passes_checkbox_day_in_tequila' | localize"
            v-model="cityPass.tequila"
            v-if="!mazatlan"
          ></v-checkbox>
          <v-checkbox
            class="mt-0"
            color="#E62076"
            :label="'city_passes_checkbox_tequila_express_train' | localize"
            v-model="cityPass.tequilaTrain"
            v-if="!mazatlan"
          ></v-checkbox>
          <span class="pl-1 color-wrapper">{{
            "status_placeholder" | localize
          }}</span>
          <v-radio-group row mandatory v-model="cityPass.status">
            <v-radio
              :label="'attraction_active_label' | localize"
              value="1"
              color="#E62076"
            ></v-radio>
            <v-radio
              :label="'attraction_inactive_label' | localize"
              value="0"
              color="#E62076"
            ></v-radio>
          </v-radio-group>

          <!-- END LOCALS CARD PREVIEW -->
        </v-card-text>
        <!-- SUBMIT FORM -->
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn
            class="mb-4"
            text
            color="#7A7A7A"
            @click="cityPassModal = false"
          >
            {{ "button_cancel" | localize }}
          </v-btn>
          <v-btn
            class="mb-4"
            v-if="!editCityPass"
            text
            color="#E62076"
            @click="addCityPass()"
          >
            {{ "city_passes_label_add_city_pass" | localize }}
          </v-btn>
          <v-btn
            class="mb-4"
            v-if="editCityPass"
            text
            color="#E62076"
            @click="updateCityPass()"
          >
            {{ "city_passes_label_update_city_pass" | localize }}
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import CitypassService from "../../requests/Citypass/CitypassService";
//import requestFormData from "@/requests/toFormData";
import { validationMixin } from "vuelidate";
import { required, requiredIf } from "vuelidate/lib/validators";
import AttractionService from "@/requests/Attractions/AttractionsService.js";
import cityMixin from "@/mixins/cityMixin";

const checkMaxDays = (value, vm) => {
  let daysReplaced = vm.days.replaceAll(",", "");
  return !(parseInt(value) > daysReplaced.length && daysReplaced.length != 7);
};

export default {
  mixins: [validationMixin, cityMixin],
  props: {
    editCityPass: {
      require: false,
    },
  },
  data: () => ({
    cityPassModal: true,
    snackbar: false,
    attractions: [],
    swatches: [
      ["#40B4E5", "#E62076", "#FA8D29"],
      ["#A459C8", "#7BDB4E", "#3BD983"],
      ["#6ABAA4", "#ba80e0", "#b39360"],
    ],
    isAdditionalChoosed: false,
    cityPass: {
      color: "#40B4E5",
      attractions: [],
      tequila: false,
      tequilaTrain: false,
      busFree: false,
      description_en: "",
      description_es: "",
      status: "1",
      days: "1,2,3,4,5,6,7",
      attraction_categories: [
        {
          uuid: "A",
          checked: false,
          count: "",
          errors: [],
        },
        {
          uuid: "B",
          checked: false,
          count: "",
          errors: [],
        },
        {
          uuid: "C",
          checked: false,
          count: "",
          errors: [],
        },
        {
          uuid: "D",
          checked: false,
          count: "",
          errors: [],
        },
      ],
    },
    attractionError: [],
    categoriesErrors: [],
    discountCitypassError: [],
    attractionDiscountError: null,
  }),
  validations() {
    return {
      cityPass: {
        titleEN: {
          required,
        },
        titleES: {
          required,
        },
        priceAdults: {
          required: requiredIf(function () {
            return this.mazatlan;
          }),
        },
        priceKids: {
          required: requiredIf(function () {
            return this.mazatlan;
          }),
        },
        term: {
          required,
          checkMaxDays,
        },
        free_attractions: {
          required,
        },
        // discount: {
        //   required,
        // },
        // discount_max: {
        //   required,
        //   minValue: minValue(parseInt(this.cityPass.discount) + 1),
        // },
        attractions: {
          required,
        },
        not_included_discount: {
          required,
        },
      },
    };
  },
  mounted() {
    console.log(this.editCityPass);
    if (this.editCityPass) {
      this.getCityPass();
    }
    this.getAttractions();
  },
  watch: {
    // "cityPass.days": {
    //   handler() {
    //     switch (this.cityPass.days) {
    //       case "1,2,3,4":
    //         this.cityPass.term = 4;
    //         this.termDisabled = true;
    //         break;
    //       case "1,2,3,4,5":
    //         this.cityPass.term = 5;
    //         this.termDisabled = true;
    //         break;
    //       case "1,2,3,4,5,6,7":
    //         this.cityPass.term = 7;
    //         this.termDisabled = true;
    //         break;
    //       case "5,6,7":
    //         this.cityPass.term = 3;
    //         this.termDisabled = true;
    //         break;
    //       case "6,7":
    //         this.cityPass.term = 2;
    //         this.termDisabled = true;
    //         break;
    //       default:
    //         this.termDisabled = false;
    //         break;
    //     }
    //   },
    // },
    cityPassModal: {
      handler() {
        this.cityPassModal = false;
        this.$emit("close_modal");
      },
    },
    "cityPass.attractions": {
      deep: true,
      handler() {
        this.cityPass.attractions.forEach((attraction) => {
          if (attraction.is_free) {
            attraction.discount_city_pass = "";
            attraction.disabled = true;
            attraction.price_adult = "";
            attraction.price_kids = "";
          } else {
            attraction.disabled = false;
          }
          if (attraction.is_additional) {
            attraction.discount_city_pass = "";
            attraction.is_free = false;
            this.isAdditionalChoosed = true;
          }
          if (attraction.discount_city_pass) {
            attraction.price_adult = "";
            attraction.price_kids = "";
          }
        });
        this.attractionDiscountError = null;
        this.attractionError = [];
        if (this.cityPass.attractions.length) {
          this.attractionsError();
        }
      },
    },
  },
  methods: {
    clearInputText() {
      this.$refs.autocompleteRef.searchValue = "";
    },
    isNumber(evt, value, name) {
      console.log(value);
      evt = evt ? evt : window.event;
      var charCode = evt.which ? evt.which : evt.keyCode;
      if (
        charCode > 31 &&
        (charCode < 48 || charCode > 57) &&
        charCode !== 46
      ) {
        evt.preventDefault();
      } else if (
        (charCode > 31 &&
          (charCode < 47 || charCode > 57) &&
          name == "attractions") ||
        (charCode > 31 &&
          (charCode < 47 || charCode > 57) &&
          name == "discount") ||
        (charCode > 31 && (charCode < 47 || charCode > 57) && name == "days")
      ) {
        evt.preventDefault();
      } else {
        if (
          name == "discount" &&
          parseFloat(value + parseFloat(evt.key)) > 100
        ) {
          evt.preventDefault();
        } else {
          if (
            parseFloat(value + parseFloat(evt.key)) < 1 ||
            (!value && evt.key == 0)
          ) {
            if (name == "kids" && parseFloat(value) != 0) {
              return true;
            }
            evt.preventDefault();
          } else return true;
        }
      }
    },
    getCityPass() {
      if (this.editCityPass.tequila_day == "1") {
        this.cityPass.tequila = true;
      }
      if (this.editCityPass.hop_bus == "1") {
        this.cityPass.busFree = true;
      }
      if (this.editCityPass.tequila_express == "1") {
        this.cityPass.tequilaTrain = true;
      }
      this.cityPass.color = this.editCityPass.color;
      if (this.editCityPass.days) {
        this.$set(this.cityPass, "days", this.editCityPass.days);
      }
      this.$set(this.cityPass, "type", this.editCityPass.type);
      this.$set(this.cityPass, "status", this.editCityPass.status);
      this.$set(
        this.cityPass,
        "description_en",
        this.editCityPass.description_en
      );
      this.$set(
        this.cityPass,
        "description_es",
        this.editCityPass.description_es
      );
      this.$set(this.cityPass, "titleEN", this.editCityPass.title_en);
      this.$set(this.cityPass, "titleES", this.editCityPass.title_es);
      this.$set(this.cityPass, "term", parseInt(this.editCityPass.term));
      this.$set(
        this.cityPass,
        "free_attractions",
        this.editCityPass.attraction_count
      );
      this.$set(
        this.cityPass,
        "not_included_discount",
        parseInt(
          parseFloat(this.editCityPass.discount_attraction_not_included) * 100
        )
      );
      // this.$set(
      //   this.cityPass,
      //   "discount",
      //   parseInt(parseFloat(this.editCityPass.discount_attraction) * 100)
      // );
      // this.$set(
      //   this.cityPass,
      //   "discount_max",
      //   parseInt(parseFloat(this.editCityPass.discount_attraction_max) * 100)
      // );

      if (this.mazatlan) {
        this.$set(this.cityPass, "priceAdults", this.editCityPass.price_adult);
        this.$set(this.cityPass, "priceKids", this.editCityPass.price_kid);
        this.editCityPass.attraction_categories.forEach((cat) => {
          let index_cat = this.cityPass.attraction_categories.findIndex(
            (el) => {
              return el.uuid == cat.uuid;
            }
          );

          if (index_cat > -1) {
            this.cityPass.attraction_categories[index_cat].checked = true;
            this.cityPass.attraction_categories[index_cat].count = cat.count;
          }
        });
      }
    },
    async updateCityPass() {
      this.$v.cityPass.$touch();

      //VALIDATION QUNATITY ATTRACTION CHOOSED
      this.attractionsError();

      //CITYPASS ATTRACTION PARAMS VALIDATION
      this.validatioCitypassAttractionParams();

      // ATTRACTION CATEGORIES VALIDATION
      let checkCategories = false;
      if (this.mazatlan) {
        let checked = false;
        let count = 0;
        let errors = false;
        this.cityPass.attraction_categories.forEach((cat) => {
          if (cat.checked) {
            checked = true;
            let category_count = this.attractions.filter(
              (el) => el.category == cat.uuid
            ).length;
            if (cat.count == "") {
              errors = true;
              cat.errors.push(
                "city_passes_attractions_category_count_required"
              );
            } else if (cat.count > category_count) {
              cat.errors.push(
                "city_passes_attractions_category_count_validation"
              );
              count += parseInt(cat.count);
            } else {
              count += parseInt(cat.count);
            }
          }
        });

        if (!checked) {
          this.categoriesErrors.push("You must select at least one category");
        } else {
          if (
            this.cityPass.free_attractions &&
            count != parseInt(this.cityPass.free_attractions)
          ) {
            this.categoriesErrors.push(
              "The number of attractions in the categories must be equal to Number of attractions included"
            );
          }
        }

        checkCategories =
          checked &&
          count == parseInt(this.cityPass.free_attractions) &&
          !errors;
        console.log(checkCategories);
      }
      // END ATTRACTION CATEGORIES VALIDATION
      !this.mazatlan ? (checkCategories = true) : ""; //Set checkCategories for correct function work
      if (
        !this.$v.cityPass.$invalid &&
        this.cityPass.attractions.length >= this.cityPass.free_attractions &&
        !this.attractionDiscountError &&
        (this.cityPass.priceAdults >= 10 || !this.mazatlan) &&
        checkCategories &&
        this.discountCitypassError.length == 0
      ) {
        let attractions = [];
        this.cityPass.attractions = this.cityPass.attractions.sort(function (
          a,
          b
        ) {
          var textA = a.title.toUpperCase();
          var textB = b.title.toUpperCase();
          return textA < textB ? -1 : textA > textB ? 1 : 0;
        });
        this.cityPass.attractions.forEach((attraction) => {
          if (attraction.price_kids && attraction.price_adult) {
            attractions.push({
              uuid: attraction.uid,
              is_free: +attraction.is_free,
              discount: !attraction.is_free
                ? parseInt(attraction.discount_city_pass) / 100
                : "",
              is_additional: +attraction.is_additional,
              price_kid: parseFloat(attraction.price_kids),
              price_adult: parseFloat(attraction.price_adult),
            });
          } else {
            attractions.push({
              uuid: attraction.uid,
              is_free: +attraction.is_free,
              discount: !attraction.is_free
                ? parseInt(attraction.discount_city_pass) / 100
                : "",
              is_additional: +attraction.is_additional,
              price_kid: 0,
              price_adult: 0,
            });
          }
        });
        console.log(this.cityPass.attractions);

        // CATEGORIES MAZATLAN
        let attraction_categories = [];
        if (this.mazatlan) {
          this.cityPass.attraction_categories.forEach((category) => {
            if (category.checked) {
              attraction_categories.push({
                uuid: category.uuid,
                count: category.count,
              });
            }
          });
        }

        let data = {
          type: this.cityPass.type,
          color: this.cityPass.color,
          // discount_attraction: parseInt(this.cityPass.discount) / 100,
          // discount_attraction_max: parseInt(this.cityPass.discount_max) / 100,
          title_en: this.cityPass.titleEN,
          title_es: this.cityPass.titleES,
          days: this.cityPass.days,
          term: this.cityPass.term,
          status: this.cityPass.status,
          attraction_count: this.cityPass.free_attractions,
          attractions: attractions,
          description_en: this.cityPass.description_en,
          description_es: this.cityPass.description_es,
          tequila_day: +this.cityPass.tequila,
          tequila_express: +this.cityPass.tequilaTrain,
          hop_bus: +this.cityPass.busFree,
          discount_attraction_not_included:
            parseInt(this.cityPass.not_included_discount) / 100,
        };

        // CATEGORIES MAZATLAN
        if (this.mazatlan) {
          data.attraction_categories = attraction_categories;
          data.price_kid = this.cityPass.priceKids;
          data.price_adult = this.cityPass.priceAdults;
        }

        //let form = requestFormData.jsonToFormData(data);
        await CitypassService.updateCitypass(this.editCityPass.uuid, data).then(
          (res) => {
            if (!res.error) {
              this.cityPassModal = false;
              this.$emit("updated");
            } else {
              this.snackbar = true;
            }
          }
        );
      }
    },
    async getAttractions() {
      await AttractionService.getAttractions().then((res) => {
        if (!this.editCityPass) {
          res.result.forEach((attraction) => {
            attraction.disabled = false;
            attraction.is_free = false;
            attraction.discount_city_pass = "";
            attraction.is_additional = false;
            attraction.price_adult = "";
            attraction.price_kids = "";
          });

          this.attractions = res.result.filter((attr) => {
            return attr.status == "Active" || attr.is_visible == "0";
          });
        } else {
          var attractions = [];
          res.result.forEach((attraction) => {
            attraction.disabled = false;
            attraction.is_free = false;
            attraction.discount_city_pass = "";
            attraction.is_additional = false;
            attraction.price_adult = "";
            attraction.price_kids = "";

            this.editCityPass.attractions.forEach((attraction_edit) => {
              if (attraction.uid == attraction_edit.uuid) {
                attractions.push(attraction);
                attraction.is_free =
                  attraction_edit.is_free == "0" || !attraction_edit.is_free
                    ? false
                    : true;
                attraction.discount_city_pass = !attraction_edit.discount
                  ? ""
                  : parseInt(attraction_edit.discount * 100);

                attraction.is_additional =
                  attraction_edit.is_additional == "0" ? false : true;
                attraction.price_adult =
                  attraction_edit.price_adult == "0.00" ||
                  !attraction_edit.price_adult
                    ? ""
                    : attraction_edit.price_adult;

                attraction.price_kids =
                  attraction_edit.price_kid == "0.00" ||
                  !attraction_edit.price_kid
                    ? ""
                    : attraction_edit.price_kid;

                attraction_edit = attraction;
              }
            });
          });

          console.log(attractions);

          attractions = attractions.sort(function (a, b) {
            var textA = a.title.toUpperCase();
            var textB = b.title.toUpperCase();
            return textA < textB ? -1 : textA > textB ? 1 : 0;
          });
          this.attractions = res.result;

          this.attractions = res.result.filter(
            (attr) => attr.status == "Active" || attr.is_visible == "0"
          );
          this.cityPass.attractions = attractions;
        }
      });
    },
    async addCityPass() {
      console.log(+this.cityPass.tequila);
      this.$v.cityPass.$touch();
      //VALIDATION QUNATITY ATTRACTION CHOOSED
      console.log(
        "work",
        this.attractionError,
        this.validatioCitypassAttractionParams()
      );
      this.attractionsError();

      //CITYPASS ATTRACTION PARAMS VALIDATION
      this.validatioCitypassAttractionParams();

      // ATTRACTION CATEGORIES VALIDATION
      let checkCategories = false;
      if (this.mazatlan) {
        let checked = false;
        let count = 0;
        let errors = false;
        this.cityPass.attraction_categories.forEach((cat) => {
          if (cat.checked) {
            checked = true;
            let category_count = this.attractions.filter(
              (el) => el.category == cat.uuid
            ).length;
            if (cat.count == "") {
              errors = true;
              cat.errors.push(
                "city_passes_attractions_category_count_required"
              );
            } else if (cat.count > category_count) {
              cat.errors.push(
                "city_passes_attractions_category_count_validation"
              );
              count += parseInt(cat.count);
            } else {
              count += parseInt(cat.count);
            }
          }
        });

        if (!checked) {
          this.categoriesErrors.push("You must select at least one category");
        } else {
          if (
            this.cityPass.free_attractions &&
            count != parseInt(this.cityPass.free_attractions)
          ) {
            this.categoriesErrors.push(
              "The number of attractions in the categories must be equal to Number of attractions included"
            );
          }
        }

        checkCategories =
          checked &&
          count == parseInt(this.cityPass.free_attractions) &&
          !errors;

        console.log(checkCategories);
      }
      // END ATTRACTION CATEGORIES VALIDATION
      !this.mazatlan ? (checkCategories = true) : ""; //Set checkCategories for correct function work
      if (
        !this.$v.cityPass.$invalid &&
        this.cityPass.attractions.length >= this.cityPass.free_attractions &&
        !this.attractionDiscountError &&
        (this.cityPass.priceAdults >= 10 || !this.mazatlan) &&
        checkCategories &&
        this.discountCitypassError.length == 0
      ) {
        let attractions = [];
        this.cityPass.attractions.forEach((attraction) => {
          if (attraction.price_kids && attraction.price_adult) {
            attractions.push({
              uuid: attraction.uid,
              is_free: +attraction.is_free,
              discount: !attraction.is_free
                ? parseInt(attraction.discount_city_pass) / 100
                : "",
              is_additional: +attraction.is_additional,
              price_kid: parseFloat(attraction.price_kids),
              price_adult: parseFloat(attraction.price_adult),
            });
          } else {
            attractions.push({
              uuid: attraction.uid,
              is_free: +attraction.is_free,
              discount: !attraction.is_free
                ? parseInt(attraction.discount_city_pass) / 100
                : "",
              is_additional: +attraction.is_additional,
              price_kid: 0,
              price_adult: 0,
            });
          }
        });

        // CATEGORIES MAZATLAN (NEED TO UNCOMMENT)

        let attraction_categories = [];
        if (this.mazatlan) {
          this.cityPass.attraction_categories.forEach((category) => {
            if (category.checked) {
              attraction_categories.push({
                uuid: category.uuid,
                count: category.count,
              });
            }
          });

          console.log(attraction_categories);
        }
        let data = {
          type: this.cityPass.type,
          color: this.cityPass.color,
          // discount_attraction: parseInt(this.cityPass.discount) / 100,
          // discount_attraction_max: parseInt(this.cityPass.discount_max) / 100,
          title_en: this.cityPass.titleEN,
          title_es: this.cityPass.titleES,
          status: this.cityPass.status,
          days: this.cityPass.days,
          term: this.cityPass.term,
          attraction_count: this.cityPass.free_attractions,
          attractions: attractions,
          tequila_day: +this.cityPass.tequila,
          tequila_express: +this.cityPass.tequilaTrain,
          hop_bus: +this.cityPass.busFree,
          description_en: this.cityPass.description_en,
          description_es: this.cityPass.description_es,
          discount_attraction_not_included:
            parseInt(this.cityPass.not_included_discount) / 100,
        };

        // CATEGORIES MAZATLAN (NEED TO UNCOMMENT)
        if (this.mazatlan) {
          data.attraction_categories = attraction_categories;
          data.price_adult = this.cityPass.priceAdults;
          data.price_kid = this.cityPass.priceKids;
        }

        //let form = requestFormData.jsonToFormData(data);
        await CitypassService.addCityPass(data).then((res) => {
          if (!res.error) {
            this.cityPassModal = false;
            this.$emit("added_city_pass");
          } else {
            this.snackbar = true;
          }
        });
      }
    },
    open() {
      this.cityPassModal = true;
    },
    attractionsError() {
      if (
        this.cityPass.attractions.length < this.cityPass.free_attractions ||
        this.cityPass.attractions.length == 0
      ) {
        this.attractionError = "city_passes_validation_number_city_passes";
      }
    },
    checkDiscountCitypassError(discount) {
      if (
        (parseInt(discount) < parseInt(this.cityPass.discount) ||
          parseInt(discount) > parseInt(this.cityPass.discount_max)) &&
        discount !== ""
      ) {
        //For correct localize filter
        this.discountCitypassError = [];
        this.discountCitypassError.push(
          "city_passes_attraction_discount_validation"
        );
        //Scroll to discount field
        this.$vuetify.goTo(this.$refs.attraction_discount, {
          container: this.$refs.content,
        });
      }
    },
    //Error if quantity free attractions > attraction length
    checkQuantityAttraction() {
      if (this.attractions.length > 0) {
        if (this.cityPass.free_attractions > this.attractions.length) {
          return "city_passes_attractions_category_count_validation";
        } else {
          return [];
        }
      }
    },
    //OPTIMIZATION CODE FOR DISCOUNT CHECK
    validatioCitypassAttractionParams() {
      this.cityPass.attractions.forEach((attraction) => {
        const included =
          !attraction.is_additional ||
          !attraction.price_adult ||
          !attraction.price_kids;
        const free = !attraction.is_free && !attraction.discount_city_pass;
        console.log("free included:", attraction, free, included);
        if (free && included) {
          console.log("work1");
          this.attractionDiscountError =
            "city_passes_choose_all_parameters_validation";
          return false;
        } else {
          console.log("work 2");
          //CHECK IS FREE ATTRACTION
          // !attraction.is_free
          //   ? this.checkDiscountCitypassError(attraction.discount_city_pass) //CHECK VALID DISCOUNT FOR MIN AND MAX VALUE
          //   : "";
          if (
            (parseInt(attraction.discount_city_pass) > 100 ||
              parseInt(attraction.discount_city_pass) < 1) &&
            !attraction.is_free
          ) {
            console.log("work 3");
            this.attractionDiscountError =
              "city_passes_discounts_range_validation";
            return false;
          }
        }
        console.log("work 4");
        return true;
      });
    },
  },
  computed: {
    titleENError() {
      const errors = [];
      if (!this.$v.cityPass.titleEN.$dirty) {
        return errors;
      }
      !this.$v.cityPass.titleEN.required &&
        errors.push("city_passes_validation_title_en_required");
      return errors;
    },
    titleESError() {
      const errors = [];
      if (!this.$v.cityPass.titleES.$dirty) {
        return errors;
      }
      !this.$v.cityPass.titleES.required &&
        errors.push("city_passes_validation_title_es_required");
      return errors;
    },
    priceAdultsError() {
      const errors = [];
      if (!this.$v.cityPass.priceAdults.$dirty) {
        return errors;
      } else if (!this.$v.cityPass.priceAdults.required) {
        errors.push("city_passes_validation_price_adults_required");
      }
      this.cityPass.priceAdults < 10 &&
        errors.push("adult_price_minimum_value_validation");
      return errors;
    },
    priceKidsError() {
      const errors = [];
      if (!this.$v.cityPass.priceKids.$dirty) {
        return errors;
      }
      !this.$v.cityPass.priceKids.required &&
        errors.push("city_passes_validation_price_kids_required");
      return errors;
    },
    freeAttractionsError() {
      const errors = [];
      if (!this.$v.cityPass.free_attractions.$dirty) {
        return errors;
      }
      !this.$v.cityPass.free_attractions.required &&
        errors.push("city_passes_validation_free_attractions_required");
      return errors;
    },
    // discountError() {
    //   const errors = [];
    //   if (!this.$v.cityPass.discount.$dirty) {
    //     return errors;
    //   }
    //   !this.$v.cityPass.discount.required &&
    //     errors.push("city_passes_validation_discount_required");
    //   return errors;
    // },
    // discountMaxError() {
    //   const errors = [];
    //   if (!this.$v.cityPass.discount_max.$dirty) {
    //     return errors;
    //   }
    //   if (!this.$v.cityPass.discount_max.minValue) {
    //     errors.push("city_passes_maximum_discount_greater_validation");
    //   }
    //   !this.$v.cityPass.discount_max.required &&
    //     errors.push("city_passes_maximum_discount_required");
    //   console.log(errors);
    //   return errors;
    // },
    notIncludedDiscountError() {
      const errors = [];
      if (!this.$v.cityPass.not_included_discount.$dirty) {
        return errors;
      }
      !this.$v.cityPass.not_included_discount.required &&
        errors.push("city_pass_not_included_discount_validation");
      return errors;
    },
    termError() {
      const errors = [];
      if (!this.$v.cityPass.term.$dirty) {
        return errors;
      }
      !this.$v.cityPass.term.required &&
        errors.push("city_passes_validation_days_required");

      if (!this.$v.cityPass.term.checkMaxDays) {
        errors.push("city_pass_days_validation");
      }
      return errors;
    },
  },
};
</script>

<style scoped>
.color-block {
  width: 50px;
  height: 22px;
  border: 1px solid #e3e3e3;
  border-radius: 4px;
  display: block;
}
.color-wrapper {
  display: flex;
  align-items: center;
  font-size: 16px;
}
.req {
  color: red !important;
}
</style>