<template>
  <v-dialog v-model="visibility" max-width="410px" persistent>
    <v-card>
      <v-card-title style="word-break: break-word !important">
        {{'delete_citypass_popup' | localize}}
      </v-card-title>
      <v-card-actions class="pb-3">
        <v-row>
          <v-col>
            <v-btn block text color="#E62076" @click="$emit('close')">
              {{ "button_no" | localize }}
            </v-btn>
          </v-col>
          <v-col>
            <v-btn color="#E62076" dark block @click="$emit('confirm')">
              {{ "button_yes" | localize }}
            </v-btn>
          </v-col>
        </v-row>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>
  
  <script>
import modalMixin from "../../mixins/modalMixin";
export default {
  mixins: [modalMixin],

};
</script>
  
  <style>
</style>