<template>
  <v-main>
      <v-container>
          <city-passes-component />
      </v-container>
  </v-main>
</template>

<script>
import cityPassesComponent from '../components/CityPasses/CityPasses.vue'
export default {
    components: {
        cityPassesComponent,
    }

}
</script>

<style>

</style>