<template>
  <v-container>
    <v-container class="pa-0 mb-8" v-if="radio == 'All' || radio == 'Tourists'">
      <v-row align="center" class="text-center">
        <v-divider class="pink darken-1" />
        <h1
          class="mx-3"
          :style="
            $vuetify.breakpoint.xs || $vuetify.breakpoint.sm
              ? 'font-size: 20px'
              : ''
          "
        >
          {{ "city_passes_for_tourists_label" | localize }}
        </h1>
        <v-divider class="pink darken-1" />
      </v-row>

      <div
        v-if="loading && touristsList.length == 0"
        class="d-flex align-center"
        style="min-height: 600px"
      >
        <v-progress-circular
          v-if="loading"
          class="mx-auto"
          indeterminate
          color="#E62076"
        ></v-progress-circular>
      </div>

      <div
        v-if="!loading && touristsList.length == 0"
        class="d-flex align-center"
        style="min-height: 300px"
      >
        <v-row justify="center"
          ><span>{{
            "city_passes_label_no_city_passes" | localize
          }}</span></v-row
        >
      </div>
      <v-row class="mt-8">
        <draggable
          v-model="touristsList"
          class="row wrap"
          :options="{ disabled: disableDraggable }"
        >
          <v-col
            align="center"
            justify="center"
            class="mx-auto col-lg-4 col-md-6 col-12"
            v-for="cityPass in touristsList"
            :key="cityPass.uuid"
          >
            <v-card
              :class="
                cityPass.status == '1'
                  ? 'd-flex flex-column'
                  : 'd-flex flex-column disabled'
              "
              min-height="100%"
              v-if="cityPass.type == '0'"
              max-width="320"
              :style="'border-color:' + cityPass.color"
              outlined
            >
              <v-card-title
                :style="'background-color:' + cityPass.color"
                class="flex-grow-0"
              >
                <v-container style="position: relative">
                  <v-btn
                    v-if="admin"
                    icon
                    absolute
                    right
                    style="margin-top: -15px; margin-right: -15px"
                    @click="
                      (showConfirmModal = true),
                        (citypassForDeleteUid = cityPass.uuid)
                    "
                  >
                    <v-icon color="white">mdi-delete</v-icon>
                  </v-btn>
                  <v-btn
                    v-if="admin"
                    icon
                    absolute
                    right
                    style="margin-top: -15px; margin-right: 15px"
                    @click="editCityPass(cityPass)"
                  >
                    <v-icon color="white">mdi-pencil</v-icon>
                  </v-btn>
                  <v-row align="center" justify="center" class="mt-1">
                    <v-divider class="white mx-3" />
                    <img
                      class="pass-logo"
                      :class="{ 'pass-logo-mzt': mazatlan }"
                      :src="
                        require('@/assets/' +
                          $options.filters.city('white_main_logo'))
                      "
                    />
                    <v-divider class="white mx-3" />
                  </v-row>
                </v-container>

                <v-container class="my-2">
                  <v-row align="center" justify="center">
                    <div class="white--text">
                      <h4 class="">
                        {{
                          userLocale == "en"
                            ? cityPass.title_en
                            : cityPass.title_es
                        }}
                      </h4>
                    </div>
                  </v-row>

                  <v-row align="center" justify="center">
                    <div class="white--text">
                      <span class="subtitle-1">{{
                        "city_passes_label_city_pass" | localize
                      }}</span>
                    </div>
                  </v-row>

                  <v-row align="center" justify="center" class="mt-10">
                    <div
                      class="white--text"
                      v-if="!cityPass.days && cityPass.term != 28"
                    >
                      <h3>
                        {{ cityPass.term }}
                        {{ "city_passes_label_days" | localize }}
                      </h3>
                    </div>
                    <div class="white--text" v-if="cityPass.term == 28">
                      <h3>1 {{ "city_passes_label_month" | localize }}</h3>
                    </div>
                    <div class="white--text" v-if="cityPass.days">
                      <h3 v-if="cityPass.days == '1,2,3,4,5'">
                        {{ "city_passes_Monday" | localize }} -
                        {{ "city_passes_Friday" | localize }}
                      </h3>
                      <h3 v-if="cityPass.days == '1,2,3,4,5,6,7'">
                        {{ "city_passes_Monday" | localize }} -
                        {{ "city_passes_Sunday" | localize }}
                      </h3>
                      <h3 v-if="cityPass.days == '1,2,3,4'">
                        {{ "city_passes_Monday" | localize }} -
                        {{ "city_passes_Thursday" | localize }}
                      </h3>
                      <h3 v-if="cityPass.days == '5,6,7'">
                        {{ "city_passes_Friday" | localize }} -
                        {{ "city_passes_Sunday" | localize }}
                      </h3>
                      <h3 v-if="cityPass.days == '6,7'">
                        {{ "city_passes_Saturday" | localize }} -
                        {{ "city_passes_Sunday" | localize }}
                      </h3>
                    </div>
                  </v-row>
                </v-container>
              </v-card-title>

              <v-card-text
                align="center"
                justify="center"
                class="mt-4 flex-grow-0"
              >
                <div class="subtitle-2 black--text">
                  <div v-if="!mazatlan" style="font-size: 17px">
                    {{ "city_passes_get_discounts_label_1" | localize }}
                    <span style="color: #e62076" class="font-weight-bold">
                      {{
                        parseInt(
                          Math.max(
                            ...cityPass.attractions
                              .filter((el) => el.discount)
                              .map((el) => el.discount)
                          ) * 100
                        )
                      }}%
                    </span>
                    {{ "city_passes_get_discounts_label_2" | localize }}
                  </div>
                  <div class="mt-2" v-if="mazatlan">
                    <span style="color: #e62076" class="font-weight-bold"
                      >MX$
                      {{
                        cityPass.price_adult
                          .toString()
                          .replace(/\B(?=(\d{3})+(?!\d))/g, ",")
                      }}</span
                    >
                    {{ "city_passes_label_adults" | localize }}
                  </div>
                  <div class="" v-if="mazatlan">
                    <span style="color: #e62076" class="font-weight-bold"
                      >MX$
                      {{
                        cityPass.price_kid
                          .toString()
                          .replace(/\B(?=(\d{3})+(?!\d))/g, ",")
                      }}</span
                    >
                    {{ "city_passes_label_kids" | localize }}
                  </div>
                  <div v-if="mazatlan">
                    <span class="font-weight-bold" style="color: #e62076">{{
                      "infants_label" | localize
                    }}</span>
                  </div>
                  <div class="mt-4" :style="mazatlan ? '' : 'font-size: 17px'">
                    {{ "city_passes_label_included_for" | localize }}
                    <span style="color: #e62076" class="font-weight-bold"
                      >{{ cityPass.term }}
                      {{ "city_passes_label_days" | localize }}</span
                    >
                  </div>
                  <div :style="mazatlan ? '' : 'font-size: 17px'">
                    {{ "city_passes_label_your_choice" | localize }}
                    <span style="color: #e62076" class="font-weight-bold"
                      >{{ cityPass.attraction_count }}
                      {{ "city_passes_label_attractions" | localize }}</span
                    >
                  </div>

                  <div class="mt-2">
                    <span
                      v-if="
                        cityPass.tequila_day == '1' ||
                        cityPass.hop_bus == '1' ||
                        cityPass.tequila_express == '1'
                      "
                      >+</span
                    >
                    <span v-if="cityPass.hop_bus == '1'">{{
                      "city_passes_checkbox_hop_on_bus" | localize
                    }}</span>
                    <span v-if="cityPass.tequila_day == '1'"
                      ><span v-if="cityPass.hop_bus == '1'">,&nbsp;</span
                      >{{
                        "city_passes_checkbox_day_in_tequila" | localize
                      }}</span
                    >
                    <span v-if="cityPass.tequila_express == '1'"
                      ><span
                        v-if="
                          cityPass.hop_bus == '1' || cityPass.tequila_day == '1'
                        "
                        >,&nbsp;</span
                      >{{
                        "city_passes_checkbox_tequila_express_train" | localize
                      }}</span
                    >
                  </div>

                  <div class="mt-2" v-if="mazatlan">
                    {{ "city_passes_label_and" | localize }}
                    <span style="color: #e62076" class="font-weight-bold">{{
                      parseInt(parseFloat(cityPass.discount_attraction) * 100) +
                      "-" +
                      parseInt(
                        parseFloat(cityPass.discount_attraction_max) * 100
                      )
                    }}</span>
                    {{ "city_passes_label_discount_part1" | localize }}
                    {{ "city_passes_label_discount_part2" | localize }}
                  </div>
                  <div style="color: #e62076" class="font-weight-bold mt-4">
                    {{
                      userLocale == "en"
                        ? cityPass.description_en
                        : cityPass.description_es
                    }}
                  </div>
                </div>
              </v-card-text>
              <v-spacer></v-spacer>
              <div
                @click="openAttractionsModal(cityPass)"
                style="
                  cursor: pointer;
                  color: #3e81de;
                  text-decoration: underline;
                  font-size: 13px;
                  font-weight: 500;
                "
              >
                {{ "city_passes_view_all_label" | localize }}
              </div>
              <v-card-actions class="justify-center mt-0 mb-1 flex-grow-0">
                <v-btn
                  color="#e62076"
                  dark
                  @click="saveCityPass(cityPass.uuid)"
                  style="
                    text-transform: unset !important;
                    width: 80%;
                    height: 60px;
                  "
                >
                  {{ "city_passes_select_button" | localize }}
                </v-btn>
              </v-card-actions>
              <v-row no-gutters justify="center" class="flex-grow-0">
                <a
                  href="https://www.hoozie.io"
                  target="_blank"
                  class="mb-3"
                  style="
                    font-weight: 600;
                    font-size: 14px;
                    line-height: 108.4%;
                    color: #6abaa4;
                    text-decoration: none;
                  "
                >
                  {{ "city_passes_label_powered_by_Hoozie" | localize }}
                </a>
              </v-row>
            </v-card>
          </v-col>
        </draggable>
      </v-row>
    </v-container>

    <v-container class="pa-0" v-if="radio == 'All' || radio == 'Locals'">
      <v-row align="center" class="text-center">
        <v-divider class="pink darken-1" />
        <h1
          class="mx-3"
          :style="
            $vuetify.breakpoint.xs || $vuetify.breakpoint.sm
              ? 'font-size: 20px'
              : ''
          "
        >
          {{ "city_passes_for_locals" | localize }}
        </h1>
        <v-divider class="pink darken-1" />
      </v-row>

      <div
        v-if="loading && localList.length == 0"
        class="d-flex align-center"
        style="min-height: 600px"
      >
        <v-progress-circular
          v-if="loading"
          class="mx-auto"
          indeterminate
          color="#E62076"
        ></v-progress-circular>
      </div>
      <div
        v-if="!loading && localList.length == 0"
        class="d-flex align-center"
        style="min-height: 300px"
      >
        <v-row justify="center"
          ><span>{{
            "city_passes_label_no_city_passes" | localize
          }}</span></v-row
        >
      </div>
      <v-row class="mt-8">
        <draggable
          v-model="localList"
          class="row wrap"
          :options="{ disabled: disableDraggable }"
        >
          <v-col
            align="center"
            justify="center"
            class="mx-auto col-lg-4 col-md-6 col-12"
            v-for="cityPass in localList"
            :key="cityPass.uuid"
          >
            <v-card
              min-height="100%"
              :class="
                cityPass.status == '1'
                  ? 'pb-3 d-flex flex-column'
                  : 'pb-3 d-flex flex-column disabled'
              "
              max-width="320"
              :style="'border-color:' + cityPass.color"
              outlined
            >
              <v-card-title class="pa-0 flex-grow-0">
                <v-container class="pa-6">
                  <v-btn
                    v-if="admin"
                    icon
                    absolute
                    right
                    style="margin-top: -15px; margin-right: -5px"
                    @click="
                      (showConfirmModal = true),
                        (citypassForDeleteUid = cityPass.uuid)
                    "
                  >
                    <v-icon color="error">mdi-delete</v-icon>
                  </v-btn>
                  <v-btn
                    v-if="admin"
                    icon
                    absolute
                    right
                    style="margin-top: -15px; margin-right: 20px"
                    @click="editCityPass(cityPass)"
                  >
                    <v-icon color="error">mdi-pencil</v-icon>
                  </v-btn>
                  <v-row align="center" justify="center" class="mt-1">
                    <v-divider class="pink darken-1 mx-3" />
                    <img
                      class="pass-logo"
                      :class="{ 'pass-logo-mzt': mazatlan }"
                      :src="
                        require('@/assets/' +
                          $options.filters.city('main_logo'))
                      "
                    />
                    <v-divider
                      class="pink darken-1 mx-3"
                      style="color: #ffffff"
                    />
                  </v-row>
                </v-container>

                <v-container class="my-2 pa-0">
                  <v-row class="mx-2" align="center" justify="center">
                    <div class="black--text" style="word-break: break-word">
                      <h4 class="">
                        {{
                          userLocale == "en"
                            ? cityPass.title_en
                            : cityPass.title_es
                        }}
                      </h4>
                    </div>
                  </v-row>

                  <v-row align="center" justify="center">
                    <div class="green--text">
                      <span class="subtitle-2">{{
                        "city_passes_label_city_pass" | localize
                      }}</span>
                    </div>
                  </v-row>

                  <v-row
                    no-gutters
                    align="center"
                    justify="center"
                    class="pa-3 mt-4"
                    :style="'background:' + cityPass.color"
                  >
                    <div
                      class="white--text"
                      v-if="!cityPass.days && cityPass.term != 28"
                    >
                      <h3>
                        {{ cityPass.term }}
                        {{ "city_passes_label_days" | localize }}
                      </h3>
                    </div>
                    <!-- <div class="white--text" v-if="cityPass.term == 28">
                      <h3>1 {{ "city_passes_label_month" | localize }}</h3><br>
                    </div> -->
                    <div class="white--text" v-if="cityPass.days">
                      <div class="white--text" v-if="cityPass.term == 28">
                        <h3>1 {{ "city_passes_label_month" | localize }}</h3>
                      </div>
                      <h3 v-if="cityPass.days == '1,2,3,4,5'">
                        {{ "city_passes_Monday" | localize }} -
                        {{ "city_passes_Friday" | localize }}
                      </h3>
                      <h3 v-if="cityPass.days == '1,2,3,4,5,6,7'">
                        {{ "city_passes_Monday" | localize }} -
                        {{ "city_passes_Sunday" | localize }}
                      </h3>
                      <h3 v-if="cityPass.days == '1,2,3,4'">
                        {{ "city_passes_Monday" | localize }} -
                        {{ "city_passes_Thursday" | localize }}
                      </h3>
                      <h3 v-if="cityPass.days == '5,6,7'">
                        {{ "city_passes_Friday" | localize }} -
                        {{ "city_passes_Sunday" | localize }}
                      </h3>
                      <h3 v-if="cityPass.days == '6,7'">
                        {{ "city_passes_Saturday" | localize }} -
                        {{ "city_passes_Sunday" | localize }}
                      </h3>
                    </div>
                  </v-row>
                </v-container>
              </v-card-title>

              <v-card-text
                align="center"
                justify="center"
                class="mt-4 flex-grow-0"
              >
                <div class="subtitle-2 black--text">
                  <div v-if="!mazatlan" style="font-size: 17px">
                    {{ "city_passes_get_discounts_label_1" | localize }}
                    <span style="color: #e62076" class="font-weight-bold"
                      >{{
                        parseInt(
                          Math.max(
                            ...cityPass.attractions
                              .filter((el) => el.discount)
                              .map((el) => el.discount)
                          ) * 100
                        )
                      }}%
                    </span>
                    {{ "city_passes_get_discounts_label_2" | localize }}
                  </div>
                  <div class="mt-2" v-if="mazatlan">
                    <span style="color: #e62076" class="font-weight-bold"
                      >MX$
                      {{
                        cityPass.price_adult
                          .toString()
                          .replace(/\B(?=(\d{3})+(?!\d))/g, ",")
                      }}</span
                    >
                    {{ "city_passes_label_adults" | localize }}
                  </div>
                  <div class="" v-if="mazatlan">
                    <span style="color: #e62076" class="font-weight-bold"
                      >MX$
                      {{
                        cityPass.price_kid
                          .toString()
                          .replace(/\B(?=(\d{3})+(?!\d))/g, ",")
                      }}</span
                    >
                    {{ "city_passes_label_kids" | localize }}
                  </div>
                  <div v-if="mazatlan">
                    <span class="font-weight-bold" style="color: #e62076">{{
                      "infants_label" | localize
                    }}</span>
                  </div>
                  <div class="mt-2" :style="mazatlan ? '' : 'font-size: 17px'">
                    {{ "city_passes_label_included_for" | localize }}
                    <span style="color: #e62076" class="font-weight-bold"
                      >{{ cityPass.term }}
                      {{ "city_passes_label_days" | localize }}</span
                    >
                  </div>

                  <div :style="mazatlan ? '' : 'font-size: 17px'">
                    {{ "city_passes_label_your_choice" | localize }}
                    <span style="color: #e62076" class="font-weight-bold"
                      >{{ cityPass.attraction_count }}
                      {{ "city_passes_label_attractions" | localize }}</span
                    >
                  </div>

                  <div class="mt-2">
                    <span
                      v-if="
                        cityPass.tequila_day == '1' ||
                        cityPass.hop_bus == '1' ||
                        cityPass.tequila_express == '1'
                      "
                      >+</span
                    >
                    <span v-if="cityPass.hop_bus == '1'">{{
                      "city_passes_checkbox_hop_on_bus" | localize
                    }}</span>
                    <span v-if="cityPass.tequila_day == '1'"
                      ><span v-if="cityPass.hop_bus == '1'">,&nbsp;</span
                      >{{
                        "city_passes_checkbox_day_in_tequila" | localize
                      }}</span
                    >
                    <span v-if="cityPass.tequila_express == '1'"
                      ><span
                        v-if="
                          cityPass.hop_bus == '1' || cityPass.tequila_day == '1'
                        "
                        >,&nbsp;</span
                      >{{
                        "city_passes_checkbox_tequila_express_train" | localize
                      }}</span
                    >
                  </div>

                  <div v-if="mazatlan">
                    {{ "city_passes_label_and" | localize }}
                    <span style="color: #e62076" class="font-weight-bold">{{
                      parseInt(parseFloat(cityPass.discount_attraction) * 100) +
                      "-" +
                      parseInt(
                        parseFloat(cityPass.discount_attraction_max) * 100
                      )
                    }}</span>
                    {{ "city_passes_label_discount_part1" | localize }}
                    {{ "city_passes_label_discount_part2" | localize }}
                  </div>
                  <div style="color: #e62076" class="font-weight-bold mt-4">
                    {{
                      userLocale == "en"
                        ? cityPass.description_en
                        : cityPass.description_es
                    }}
                  </div>
                </div>
              </v-card-text>
              <v-spacer />
              <div
                @click="openAttractionsModal(cityPass)"
                style="
                  cursor: pointer;
                  color: #3e81de;
                  text-decoration: underline;
                  font-size: 13px;
                  font-weight: 500;
                "
              >
                {{ "city_passes_view_all_label" | localize }}
              </div>
              <v-card-actions class="justify-center my-auto flex-grow-0">
                <v-btn
                  @click="saveCityPass(cityPass.uuid)"
                  color="#e62076"
                  dark
                  style="
                    text-transform: unset !important;
                    width: 80%;
                    height: 60px;
                  "
                >
                  {{ "city_passes_select_button" | localize }}
                </v-btn>
              </v-card-actions>
              <v-row no-gutters justify="center" class="flex-grow-0">
                <a
                  href="https://www.hoozie.io"
                  target="_blank"
                  style="
                    font-weight: 600;
                    font-size: 14px;
                    line-height: 108.4%;
                    color: #6abaa4;
                    text-decoration: none;
                  "
                >
                  {{ "city_passes_label_powered_by_Hoozie" | localize }}
                </a>
              </v-row>
            </v-card>
          </v-col>
        </draggable>
      </v-row>
    </v-container>
    <add-city-pass-modal
      ref="modal"
      v-if="cityPassModal"
      @close_modal="closeModal"
      @updated="updatedCityPass()"
      :editCityPass="cityPassEdit"
    />
    <all-attractions-modal
      v-if="attractionsModal"
      @close_attractions_modal="closeAttractionsModal()"
      :cityPass="cityPassEdit"
    />
    <!-- <announcement-modal
      :visible="announcementModal"
      v-if="announcementModal"
      @close="announcementModal = false"
    /> -->
    <confirm-delete-citypass-modal
      v-if="showConfirmModal"
      :visible="showConfirmModal"
      @confirm="showConfirmModal = false; deleteCityPass()"
      @close="showConfirmModal = false"
    />
  </v-container>
</template>

<script>
import CitypassService from "../../requests/Citypass/CitypassService";
import { mapGetters } from "vuex";
import AddCityPassModal from "./AddCityPassModal.vue";
import draggable from "vuedraggable";
import AllAttractionsModal from "./AllAttractionsModal.vue";
import cityMixin from "@/mixins/cityMixin";
//import announcementModal from "@/components/UI/announcementModal.vue";
import serverMixin from "@/mixins/serverMixin";
import ConfirmDeleteCitypassModal from "./confirmDeleteCitypassModal.vue";
export default {
  components: {
    AddCityPassModal,
    draggable,
    AllAttractionsModal,
    //announcementModal,
    ConfirmDeleteCitypassModal,
  },
  mixins: [cityMixin, serverMixin],
  props: {
    title: {
      require: true,
    },
    radio: {
      require: true,
    },
  },
  data: () => ({
    localList: [],
    touristsList: [],
    loading: true,
    cityPassList: [],
    admin: false,
    attractionsModal: false,
    cityPassModal: false,
    cityPassEdit: null,
    disableDraggable: true,
    //announcementModal: false,
    showConfirmModal: false,
    citypassForDeleteUid: "",
    userLocale: "",
  }),
  computed: {
    ...mapGetters(["loggedUser"]),
    ...mapGetters(["user"]),
  },
  methods: {
    openAttractionsModal(cityPass) {
      this.cityPassEdit = cityPass;
      this.attractionsModal = true;
    },
    closeAttractionsModal() {
      this.attractionsModal = false;
    },
    editCityPass(cityPass) {
      this.cityPassEdit = cityPass;
      this.openModal();
    },
    closeModal() {
      this.cityPassModal = false;
    },
    openModal() {
      this.cityPassModal = true;
    },
    saveCityPass(uuid) {
      localStorage.setItem("citypass_uuid", uuid);
      this.$router.push("/attractions");
      // if (this.isProd && !this.mazatlan) {
      //   this.announcementModal = true;
      // } else {
      //   localStorage.setItem("citypass_uuid", uuid);
      //   this.$router.push("/attractions");
      // }
    },
    stopLoading() {
      this.loading = false;
    },
    startLoader() {
      this.loading = true;
    },
    updatedCityPass() {
      this.localList = [];
      this.touristsList = [];
      this.loading = true;
      this.$emit("get_city_passes");
    },
    async deleteCityPass() {
      await CitypassService.deleteCityPass(this.citypassForDeleteUid);
      this.localList = [];
      this.touristsList = [];
      this.loading = true;
      this.$emit("get_city_passes");
    },
    pushToHoozie() {
      window.open("https://www.hoozie.io");
    },
    async updateOrderCityPassList(list, index) {
      let data = {
        type: list[index].type,
        color: list[index].color,
        discount_attraction: list[index].discount_attraction,
        title_en: list[index].title_en,
        title_es: list[index].title_es,
        price_kid: list[index].price_kid,
        price_adult: list[index].price_adult,
        days: list[index].days,
        term: list[index].term,
        attraction_count: list[index].attraction_count,
        attractions: list[index].attractions,
        position: index,
        tequila_day: +parseInt(list[index].tequila_day),
        tequila_express: +parseInt(list[index].tequila_express),
        hop_bus: +parseInt(list[index].hop_bus),
      };

      //let form = requestFormData.jsonToFormData(data);
      await CitypassService.updateCitypass(list[index].uuid, data);
    },
    setCityPasses(cityPassList) {
      this.loading = false;
      this.cityPassList = cityPassList;
      this.cityPassList.sort((a, b) => {
        return parseInt(a.position) - parseInt(b.position);
      });

      this.localList = [...this.cityPassList].filter(function (el) {
        return el.type == "1";
      });

      this.touristsList = [...this.cityPassList].filter(function (el) {
        return el.type == "0";
      });
    },
  },
  mounted() {
    if (this.loggedUser && this.loggedUser.admin) {
      this.admin = true;
      this.disableDraggable = false;
    }
    this.userLocale = this.loggedUser
      ? this.loggedUser.locale
      : this.user.locale;
  },
  watch: {
    loggedUser: {
      handler() {
        if (this.loggedUser && this.loggedUser.admin) {
          this.admin = true;
        }
      },
    },
    touristsList: {
      deep: true,
      handler() {
        if (this.admin) {
          for (let index = 0; index < this.touristsList.length; index++) {
            this.updateOrderCityPassList(this.touristsList, index);
          }
        }
      },
    },
    localList: {
      deep: true,
      handler() {
        if (this.admin) {
          for (let index = 0; index < this.localList.length; index++) {
            console.log("change");
            this.updateOrderCityPassList(this.localList, index);
          }
        }
      },
    },
    "loggedUser.locale": {
      deep: true,
      handler() {
        this.userLocale = this.loggedUser
          ? this.loggedUser.locale
          : this.user.locale;
      },
    },
    "user.locale": {
      deep: true,
      handler() {
        this.userLocale = this.loggedUser
          ? this.loggedUser.locale
          : this.user.locale;
      },
    },
  },
};
</script>

<style lang="scss" scoped>
.disabled {
  opacity: 0.2;
}
</style>