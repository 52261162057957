<template>
  <v-row align="center" class="px-3 mt-1 mb-2">
    <h1>{{'header_city_passes_part1' | localize}}</h1>
    <v-radio-group
      v-model="radio"
      row
      @change="changed"
      mandatory
      class="ml-5"
    >
        <v-radio
          :label="'city_passes_all' | localize"
          value="All"
        ></v-radio>
        <v-radio
         :label="'city_passes_tourists' | localize"
          value="Tourists"
        ></v-radio>
        <v-radio
         :label="'city_passes_locals' | localize"
          value="Locals"
        ></v-radio>
    </v-radio-group>
    <v-divider class="mt-3 ml-3" v-if="!$vuetify.breakpoint.xs && !$vuetify.breakpoint.sm" />
  </v-row>
</template>

<script>
export default {
  data: () => ({
    radio: "All",
  }),  
  methods:{
    changed() {
      this.$emit('radioChanged',this.radio)
    },
  }
};
</script>

<style>
</style>